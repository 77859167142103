import { Box } from "@chakra-ui/react";
import poster from "assets/home/promo/poster.png";

export default function Promo() {
  return (
    <Box
      mx={"auto"}
      w={"95%"}
      maxW={"1000px"}
      p={{ base: 0, lg: "20px" }}
      boxShadow={"lg"}
      borderRadius={{ base: "16px", lg: "36px" }}
      overflow={"hidden"}
      zIndex={3}
      my={"80px"}
      h={"fit-content"}
    >
      <video
        controls
        preload={"none"}
        poster={poster}
        style={{ width: "100%", height: "auto", borderRadius: "16px" }}
      >
        <source
          src="https://lakesai.s3.eu-central-1.amazonaws.com/moleculelake_demo_video_1.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
}
