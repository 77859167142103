import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Highlight,
  Image,
  Stack,
  Text,
  useMediaQuery,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import { useStripe } from "@stripe/react-stripe-js";
import { useStripeAPI } from "services/stripe.service";

import { hexToRgba } from "utils/helpers";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import LoginBg from "assets/man-walking.jpg";
import MobileLoginToast from "components/authforms/loginInputs/MobileLoginToast";

export default function AuthLayout() {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  // Theme
  const { colors } = useTheme();

  // Stripe
  const stripe = useStripe();

  // APIs
  const { createCheckoutSession } = useStripeAPI();

  const from = location.state?.from || null;

  const teamInviteLink = localStorage.getItem("teamInviteLink") || "";

  const plan = localStorage.getItem("planFromHomePage");
  const planFromHomePage = plan ? JSON.parse(plan) : null;

  const { user } = useSelector(selectCurrentAuthData);
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  useEffect(() => {
    let redirectTo = "/chat";
    if (teamInviteLink?.length > 0 && user) {
      redirectTo = teamInviteLink;
      localStorage.removeItem("teamInviteLink");
    } else if (from?.pathname?.length > 0 && from?.search?.length > 0) {
      redirectTo = from.pathname + from.search;
    } else if (from?.pathname?.length > 0 && from?.search?.length === 0) {
      redirectTo = from.pathname;
    } else if (isMobileView && !!user) {
      redirectTo = "/";

      toast({
        position: "bottom",
        duration: 10000,
        render: () => <MobileLoginToast />,
      });
    }

    const CreateCheckoutSession = async () => {
      if (!stripe) {
        // Stripe.js has not yet loaded.
        // TODO: Handle errors
        return;
      }

      let session: any;
      const payload = {
        email: user?.email,
        username: `${user?.given_name}${user?.family_name}`,
        plan: planFromHomePage.plan,
        price: parseFloat(planFromHomePage.price),
        currency: planFromHomePage.currency,
        is_yearly: planFromHomePage.is_annual === "true" ? true : false,
        payment_mode: "subscription", // subscription => recurring, payment => one off payment
      };

      await createCheckoutSession(payload)
        .then(async (res) => {
          session = res;
        })
        .catch((error) => {
          console.log(error);
          toast({
            description: error.response.data.message
              ? error.response.data.message
              : "Error initializing checkout",
            status: "error",
            position: "top-right",
          });
        });

      if (!session) {
        // TODO: Handle errors
        return;
      }

      // Redirect to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        // Handle error here.
        console.error(result.error.message);
        toast({
          description: result.error.message
            ? result.error.message
            : "Error initializing checkout",
          status: "error",
          position: "top-right",
          duration: 6000,
        });
      }
    };

    if (planFromHomePage && user) {
      if (planFromHomePage.plan.toLowerCase() === user.user_type) {
        navigate(redirectTo, { replace: true });
      } else {
        CreateCheckoutSession();
      }
      localStorage.removeItem("planFromHomePage");
    } else {
      if (user) navigate(redirectTo, { replace: true });
    }
  }, [
    from,
    navigate,
    user,
    teamInviteLink,
    planFromHomePage,
    createCheckoutSession,
    stripe,
    toast,
    isMobileView,
  ]);

  return (
    <>
      {isMobileView ? (
        <Outlet />
      ) : (
        <Grid h={"100vh"} templateColumns={`repeat(7, 1fr)`} scaleY={"none"}>
          <GridItem colSpan={2} />
          <GridItem colSpan={5} bg={"primary.700"}>
            <Box
              backgroundImage={LoginBg}
              bgSize={"cover"}
              bgPosition={"top"}
              bgRepeat={"no-repeat"}
              h={"100vh"}
              position={"relative"}
            >
              {/* Color overlay */}
              <Box
                bgGradient={`linear(to-br, ${hexToRgba(
                  colors.primary[700],
                  0.1
                )}, ${hexToRgba(colors.primary[700], 0.65)}, ${hexToRgba(
                  colors.primary[700],
                  0.9
                )}, ${hexToRgba(colors.primary[700], 1)})`}
                p={"4"}
                position={"absolute"}
                top={"0"}
                left={"0"}
                right={"0"}
                bottom={"0"}
                color={"whiteAlpha.900"}
              >
                {/* Header Content */}
                <Flex
                  h={"100%"}
                  w={"100%"}
                  direction={"column"}
                  justify={"center"}
                  textAlign={"left"}
                  ml={"38%"}
                >
                  {/* Logo */}
                  <Flex mb={6} justifyContent={"left"}>
                    <Center>
                      <Image
                        src={
                          "https://reacto.s3.eu-central-1.amazonaws.com/logo/white.svg"
                        }
                        h={"80px"}
                        mr={4}
                        opacity={0.6}
                      />
                      <Heading color={"whiteAlpha.900"}>Lakesai</Heading>
                    </Center>
                  </Flex>

                  {/* Our mission  */}
                  <Stack
                    maxW={{ lg: "60%", xl: "500px", "2xl": "530px" }}
                    flexWrap={"wrap"}
                  >
                    <Text
                      fontSize={[null, null, null, "md", "lg", "xl"]}
                      color={"whiteAlpha.900"}
                      lineHeight={"1.6"}
                    >
                      <Highlight
                        query="intelligent agent"
                        styles={{
                          px: "2",
                          py: "1",
                          bg: "whiteAlpha.800",
                          opacity: "0.6",
                          color: "primary.800",
                          rounded: "full",
                          fontWeight: "bold",
                        }}
                      >
                        Your intelligent agent for accelerating scientific discoveries.
                      </Highlight>
                    </Text>
                  </Stack>
                </Flex>
              </Box>
            </Box>
          </GridItem>

          {/* Login */}
          <Box
            position={"absolute"}
            transform={"translateY(-50%) translateX(-50%)"}
            left={"calc(2/7 * 100%)"}
            top={"50%"}
            w={"fit-content"}
          >
            <Outlet />
          </Box>
        </Grid>
      )}
    </>
  );
}
