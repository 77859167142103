import {
  Flex,
  Icon,
  Text,
  Link,
  useTheme,
  Button,
  useToast,
  Box,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  SavedBotMessagesProps,
  SavedFolderProps,
} from "models/bookmarks/SavedProps";
import { MessageProps } from "models/chat/MessageProps";
import React from "react";
import { BsRobot } from "react-icons/bs";
import { GoBookmarkSlash } from "react-icons/go";
import { LuExternalLink } from "react-icons/lu";
import { RiSpeakLine } from "react-icons/ri";
import { hexToRgba } from "utils/helpers";

import { IconType } from "react-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { environment } from "environments";

type ProcessedData = Pick<
  SavedBotMessagesProps,
  "id" | "session_id" | "messages"
>;

interface DeleteBotMessageProps {
  elementType: "BOTMESSAGE";
  content: {
    elementId: string | undefined;
  };
}

interface LinkToChatProps {
  name: string;
  icon: IconType;
  link: string;
}

interface DeleteChatButtonProps {
  onClick: () => void;
}

interface BotMessageProps {
  id: string;
  question: string;
  session_id?: string;
}

function BotMessage({ question, session_id, id }: BotMessageProps) {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const { folderId } = useParams();
  const axiosPrivate = useAxiosPrivate();

  const { savedFolders } = useSelector(selectCurrentSavedData);

  // Handler: delete item from bookmark folder
  async function handleDeleteItem({
    elementType,
    content,
  }: DeleteBotMessageProps) {
    if (!content) return;

    try {
      const newFolders = savedFolders.map((f: SavedFolderProps) => {
        if (f.id === folderId) {
          return {
            ...f,
            botMessages: f?.botMessages?.filter((item: { id: string }) => {
              return item.id !== content.elementId;
            }),
          };
        }

        return f;
      });

      dispatch(setSuccess(newFolders));

      await axiosPrivate.put(`${environment.BACKEND_API}/api/remove_elements`, {
        folderId,
        elementType,
        content,
      });
    } catch (error: any) {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 1500,
        position: "top-right",
      });
      console.log(error);
    }
  }

  return (
    <Flex direction={"column"} gap={2} p={1.5}>
      <Flex align={"center"} gap={2}>
        <Icon as={RiSpeakLine} color={"secondary.600"} boxSize={4} />
        <Text
          minW={"600px"}
          maxW={"80%"}
          fontSize={"13px"}
          fontWeight={"500"}
          color={"gray.600"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          {question}
        </Text>
      </Flex>

      <Flex align={"center"} gap={1} p={0}>
        {!!session_id && (
          <LinkToChatButton
            name={"Explore full conversation"}
            icon={LuExternalLink}
            link={`/chat/${session_id}`}
          />
        )}

        {!!id && (
          <DeleteChatButton
            onClick={() =>
              handleDeleteItem({
                elementType: "BOTMESSAGE",
                content: { elementId: id },
              })
            }
          />
        )}
      </Flex>
    </Flex>
  );
}

function LinkToChatButton({ name, icon, link }: LinkToChatProps) {
  return (
    <Link
      as={RouterLink}
      to={link}
      display={"flex"}
      alignItems={"center"}
      gap={1}
      color={"gray.500"}
      border={"none"}
      p={0}
      transition={"all .2s ease"}
      _hover={{
        textDecoration: "underline",
        color: "blue.400",
        transition: "all .2s ease",
      }}
    >
      <Icon as={icon} boxSize={3} />
      <Text fontFamily={"Poppins, sans-serif"} fontSize={"12px"} lineHeight={1}>
        {name}
      </Text>
    </Link>
  );
}

function DeleteChatButton({ onClick }: DeleteChatButtonProps) {
  return (
    <Button
      iconSpacing={1}
      leftIcon={<GoBookmarkSlash />}
      bg={"transparent"}
      color={"gray.500"}
      borderRadius={"50%"}
      boxShadow={"none"}
      border={"none"}
      h={"fit-content"}
      _hover={{
        textDecoration: "underline",
        bg: "transparent",
        color: "blue.400",
        transition: "all .2s ease",
      }}
      _active={{
        textDecoration: "underline",
        bg: "transparent",
        color: "blue.400",
        transition: "all .2s ease",
      }}
      onClick={onClick}
      fontFamily={"Poppins, sans-serif"}
      fontSize={"12px"}
      fontWeight={"400"}
      lineHeight={1}
    >
      Delete
    </Button>
  );
}

function BotMessages({ content }: { content: SavedBotMessagesProps[] }) {
  // Theme
  const { colors } = useTheme();

  const processedData: ProcessedData[] = content.map((item) => ({
    id: item.id,
    session_id: item.session_id,
    messages: item.messages,
  }));

  return (
    <Flex direction={"column"} gap={4} w={"100%"}>
      <Flex align={"center"} gap={2}>
        <Flex
          bg={hexToRgba(colors.blue[300], 0.15)}
          borderRadius={"70% 30% 30% 70% / 60% 40% 60% 40%"}
          p={1.5}
        >
          <Icon as={BsRobot} boxSize={"16px"} />
        </Flex>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Assistant
        </Text>
      </Flex>

      <Flex direction={"column"} gap={4}>
        {processedData?.map((d: ProcessedData, idx: number) => (
          <Flex key={idx} direction={"column"} gap={4}>
            {d?.messages?.map((m: MessageProps, idx: number) => (
              <BotMessage
                id={d?.id}
                key={idx}
                question={m?.human}
                session_id={d?.session_id}
              />
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

export default BotMessages;
