import { useEffect, useMemo, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import {
  Box,
  Center,
  Modal,
  TabPanel,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import {
  LinkProps,
  NodePosition,
  NodeProps,
  PapersNetworkProps,
  checkOrigin,
  generateLinks,
  generateNodes,
  toolTipFormatter,
} from "./helpers";
import { PaperProps } from "models/papers/PaperProps";
import CustomNodeContextMenu from "./CustomNodeContextMenu";
import { useQueryClient } from "@tanstack/react-query";
import { useLiteraturesAPI } from "api/useLiteraturesAPI";
import SelectedNodeDetails from "./SelectedNodeDetails";
import { errorHandler } from "utils/helpers";
import Loading from "components/ui/Loading";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentEmbeddingData } from "redux/features/embedding/embeddingSlice";
import useEmbeddingProcess from "hooks/literatureEmbedding/useEmbeddingProcess";
import FoldersModal from "components/library/bookmarks/modal/FoldersModal";
import { truncateString } from "components/ui/helpers";

const NetworkStyle = {
  height: "100%",
  width: "100%",
  margin: "auto",
};

interface SourcesProps {
  sources: PapersNetworkProps[];
}

export interface MenuPositionProps {
  x: number;
  y: number;
}

function NetworkTabPanel({ sources }: SourcesProps) {
  const toast = useToast();
  const { colors } = useTheme();
  const [data, setData] = useState(sources);
  const panelRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuPosition, setMenuPosition] = useState<MenuPositionProps | null>(
    null
  );
  const [clickedNode, setClickedNode] = useState<NodeProps | null>(null);
  const [selectedNode, setSelectedNode] = useState<PaperProps | null>(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [newOriginLoading, setNewOriginLoading] = useState(false);
  const [nodes, setNodes] = useState<NodeProps[]>([]);
  const [links, setLinks] = useState<LinkProps[]>([]);
  const [nodesPositions, setNodesPositions] = useState<NodePosition[]>([]);
  const [allTrackedPapers, setAllTrackedPapers] = useState<PaperProps[]>([]);

  useEffect(() => setData(sources), [sources]);

  // handle ContextMenu hiding
  useEffect(() => {
    const hideMenu = () => {
      setClickedNode(null);
      setMenuPosition(null);
    };
    const handleHideContextMenu = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event?.target))
        hideMenu();
    };

    document.addEventListener("mousedown", handleHideContextMenu);
    window.addEventListener("resize", hideMenu);
    panelRef.current?.addEventListener("mouseleave", hideMenu);

    return () => {
      document.removeEventListener("mousedown", handleHideContextMenu);
      window.removeEventListener("resize", hideMenu);
      panelRef.current?.removeEventListener("mouseleave", hideMenu);
    };
  }, []);

  const referencePapers = useMemo(
    () => data?.map((item) => item.reference_paper),
    [data]
  );

  const addedPaperIds = new Set();
  const allSimilarPapers = useMemo(() => {
    return data.flatMap((item) => {
      return item.similar_papers
        .filter((paper) => {
          const isNotReferencePaper = !checkOrigin(paper.id, referencePapers);
          const isNotAddedYet = !addedPaperIds.has(paper.id);
          const isNotOriginPaper = paper.id !== item.reference_paper.id;

          if (isNotReferencePaper && isNotAddedYet && isNotOriginPaper) {
            addedPaperIds.add(paper.id);
            return true;
          }
          return false;
        })
        .map((paper) => ({
          ...paper,
          db_type: item.reference_paper.db_type,
        }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const chartData = useMemo(() => {
    let da = referencePapers
      .concat(allSimilarPapers)
      .sort(() => Math.random() - 0.5);
    setAllTrackedPapers(da);

    return da;
  }, [referencePapers, allSimilarPapers]);

  useEffect(() => {
    let nds = generateNodes(
      chartData,
      referencePapers,
      nodesPositions,
      setNodesPositions,
      chartData.length
    );
    setNodes(nds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  useEffect(() => {
    let lks = generateLinks(data);
    setLinks(lks);
  }, [data]);

  const categories = [
    { name: "Sources", keyword: {}, base: "sources" },
    { name: "Referenced in", keyword: {}, base: "Referenced in" },
    { name: "References", keyword: {}, base: "References" },
  ];

  const legend = {
    bottom: "bottom",
    data: ["Sources", "Referenced in", "References"],
    icon: "circle",
    itemGap: 20,
    textStyle: {
      fontSize: 11,
      fontWeight: "semi-bold",
      fontFamily: "Poppins, sans-serif",
      color: colors.gray[600],
    },
  };

  const option = {
    legend,
    animation: true,
    animationDuration: 1000,
    animationEasingUpdate: "quinticInOut",
    color: ["#00c1b4", "#63B3ED", "#FFA62B"],
    tooltip: {
      z: 60,
      formatter: toolTipFormatter,
      show: true,
      trigger: "item",
      triggerOn: "mousemove|click",
      alwaysShowContent: false,
      confine: true,
      hideDelay: 100,
      transitionDuration: 0.4,
    },
    series: [
      {
        name: "Network Graph",
        type: "graph",
        legendHoverLink: false,
        layout: "none",
        draggable: true,
        label: {
          show: true,
          position: "top",
          fontSize: 10,
          distance: 2,
          formatter: (params: { data: { name: string; year: number } }) =>
            `${truncateString(params.data.name, 25)}`,
          fontFamily: "Arial",
          color: colors.gray[500],
          borderColor: "transparent",
          borderWidth: 0.5,
          padding: [2, 3],
          borderRadius: 2,
        },
        lineStyle: {
          color: "source",
          curveness: 0.2,
          width: 1,
          opacity: 0.5,
        },
        emphasis: {
          borderWidth: 5,
          scale: false,
          focus: "adjacency",
          lineStyle: {
            width: 2,
          },
        },
        data: nodes,
        edges: links,
        categories: categories,
        edgeLabel: {
          position: "middle",
          distance: 5,
        },
      },
    ],
  };

  const onEvents = {
    contextmenu: (params: any) => {
      if (params?.dataType === "node") {
        params.event.event.preventDefault();
        const { pageX, pageY } = params.event.event;
        const { data, isOrigin } = params.data;

        // Get the dimensions of the parent node (panelRef)
        const panel = panelRef.current;
        if (panel) {
          const panelRect = panel.getBoundingClientRect();
          const menuWidth = 280;
          const menuHeight =
            250 - (data.embedded ? 32 : 0) - (isOrigin ? 32 : 0);

          let adjustedPageX = pageX;
          let adjustedPageY = pageY;

          // Ensure the menu doesn't overflow the panel boundaries
          if (adjustedPageX + menuWidth >= panelRect.right) {
            adjustedPageX = pageX - menuWidth;
          }
          if (adjustedPageY + menuHeight >= panelRect.bottom) {
            adjustedPageY = pageY - menuHeight;
          }
          setMenuPosition({ x: adjustedPageX, y: adjustedPageY });
          setClickedNode(params.data);
        }
      }
    },
    click: (params: any) => {
      if (params?.dataType === "node") {
        setSelectedNode(params.data.data);
      }
    },
  };

  const { fetchSimilarPapers } = useLiteraturesAPI();
  const queryClient = useQueryClient();

  const handleSetAsOrigin = async () => {
    try {
      setNewOriginLoading(true);

      const response = (await queryClient.fetchQuery({
        queryKey: [
          "literatures",
          "network",
          clickedNode?.id,
          clickedNode?.data.db_type,
        ],
        queryFn: fetchSimilarPapers,
        staleTime: 10000,
      })) as PapersNetworkProps;

      let papers = response.similar_papers.filter(
        (it) => !allTrackedPapers.some((pa: PaperProps) => it.id === pa.id)
      );
      setAllTrackedPapers((prev) => [...prev, ...papers]);

      let nds = generateNodes(
        papers,
        [response.reference_paper],
        nodesPositions,
        setNodesPositions,
        allTrackedPapers.length
      );

      setNodes((prev) => {
        return prev
          .map((n) => {
            if (n.id === response.reference_paper.id) {
              return {
                ...n,
                isOrigin: true,
                highlighted: true,
                symbolSize: 35,
                category: 0,
                label: {
                  ...n.label,
                  show: true,
                },
                itemStyle: {
                  ...n.itemStyle,
                  color: "#00c1b4",
                  borderColor: "red",
                },
              };
            }
            return n;
          })
          .concat(nds);
      });

      setTimeout(() => {
        setNodes((prev) => {
          return prev.map((n) => {
            if (n.highlighted) {
              return {
                ...n,
                highlighted: false,
                itemStyle: {
                  ...n.itemStyle,
                  borderColor: "transparent",
                },
              };
            }
            return n;
          });
        });
      }, 4000);

      // add links
      setLinks((prev) => prev.concat(...generateLinks([response])));
    } catch (error) {
      toast({
        description: errorHandler(error).message,
        status: "warning",
      });
    } finally {
      setNewOriginLoading(false);
    }
  };

  // Handle Embed Paper
  const { user } = useSelector(selectCurrentAuthData);
  const { progress } = useSelector(selectCurrentEmbeddingData);
  const { initiateSocket } = useEmbeddingProcess();

  const isPremiumUser = user?.user_type === "premium";
  const isEmbeddingInProgress = progress !== null && progress < 100;

  const handleEmbedPaper = async (paper: string) => {
    // Embedding Only for premium
    if (user?.user_type !== "premium") return;

    // Don't allow multiple embeddings
    if (isEmbeddingInProgress) {
      toast({
        description: "An embed operation in progress, please wait",
        status: "error",
        position: "top-right",
      });
      return;
    }

    // Connect to a socket
    initiateSocket(paper);
  };

  return (
    <TabPanel height={"510px"} py={2} px={0} ref={panelRef}>
      <>
        <Box position="relative" height={"100%"} width={"100%"}>
          {newOriginLoading && (
            <Center
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              backdropFilter="blur(4px)"
              zIndex={10}
            >
              <Loading message="Setting new origin..." />
            </Center>
          )}
          <ReactECharts
            option={option}
            onEvents={onEvents}
            notMerge={true}
            lazyUpdate={true}
            style={NetworkStyle}
          />
          {menuPosition && clickedNode && (
            <Box ref={menuRef} onContextMenu={(e) => e.preventDefault()}>
              <CustomNodeContextMenu
                x={menuPosition.x}
                y={menuPosition.y}
                node={clickedNode}
                isEmbeddingInProgress={isEmbeddingInProgress}
                isPremiumUser={isPremiumUser}
                onClose={() => setMenuPosition(null)}
                handleSaveAction={() => setShowSaveModal(true)}
                handleSetAsOriginAction={handleSetAsOrigin}
                handleEmbedAction={handleEmbedPaper}
              />
            </Box>
          )}
        </Box>

        {/* Save Modal */}
        {clickedNode?.id && (
          <FoldersModal
            isOpen={showSaveModal}
            onClose={() => setShowSaveModal(false)}
            payload={{
              saveElementPayload: {
                elementType: "LITERATURE",
                content: {
                  elementId: clickedNode?.id,
                },
              },
              successMessage: `Literature is successfully saved.`,
            }}
          />
        )}

        {/* Selected Paper Details */}
        <Modal isOpen={!!selectedNode} onClose={() => setSelectedNode(null)}>
          {selectedNode && <SelectedNodeDetails data={selectedNode} />}
        </Modal>
      </>
    </TabPanel>
  );
}

export default NetworkTabPanel;
